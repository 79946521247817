@tailwind base;
/* Firefox */

@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@layer base {
  :root {
    /*
    *
    * This all are the rgb values which are used inside the
    * tailwind config file and custom plugins css file
    *
    */

    /*
    * primary color
    */
    --color-accent: 83, 116, 108;

    /*
    * Hover state color of primary color
    */
    --color-accent-hover: 212, 159, 0;

    /*
    * shade of primary color
    */
    --color-accent-200: 204, 236, 229;

    /*
    * shade of primary color
    */
    --color-accent-300: 153, 217, 204;

    /*
    * shade of primary color
    */
    --color-accent-400: 77, 188, 165;

    /*
    * shade of primary color
    */
    --color-accent-500: 0, 159, 127;

    /*
    * shade of primary color
    */
    --color-accent-600: 0, 143, 114;

    /*
    * shade of primary color
    */
    --color-accent-700: 0, 119, 95;

    /*
    * light color
    */
    --color-light: 255, 255, 255;

    /*
    * dark color
    */
    --color-dark: 0, 0, 0;

    /*
    * Black muted color
    */
    --color-muted-black: 17, 17, 17;

    /*
    * Current color
    */
    --color-current: currentColor;


    /***
     * secondary color 
     * */

    --color-secondary: 212, 159, 0;

    /*
    *
    * TEXT colors
    *
    */

    /*
    * text color used for body/base text -> gray-500
    */
    --text-base: 63, 63, 68;

    /*
    * dark base text color  -> gray-600
    */
    --text-base-dark: 75, 85, 99;

    /*
    * muted text color -> gray-400
    */
    --text-muted: 156, 163, 175;

    /*
    * light muted text color - gray-300
    */
    --text-muted-light: 209, 213, 219;

    /*
    * text color used for heading text -> gray-700
    */
    --text-sub-heading: 55, 65, 81;

    /*
    * text color used for heading text -> gray-800
    */
    --text-heading: 107, 114, 128;

    /*
    * text color used for heading text -> gray-900
    */
    --text-bolder: 31, 41, 55;

    /*
    *
    * border colors
    *
    */

    /*
    * border color 50
    */
    --color-border-50: 249, 250, 251;

    /*
    * border color 100
    */
    --color-border-100: 243, 244, 246;

    /*
    * border color 200
    */
    --color-border-200: 229, 231, 235;

    /*
    * border base color which is 300
    */
    --color-border-base: 209, 213, 219;

    /*
    * border color 400
    */
    --color-border-400: 156, 163, 175;

    /*
    *
    * Tailwind default gray color override
    *
    */

    /*
    * gray-50
    */
    --color-gray-50: 249, 250, 251;

    /*
    * gray-100
    */
    --color-gray-100: 243, 244, 246;

    /*
    * gray-200
    */
    --color-gray-200: 229, 231, 235;

    /*
    * gray-300
    */
    --color-gray-300: 209, 213, 219;

    /*
    * gray-400
    */
    --color-gray-400: 156, 163, 175;

    /*
    * gray-500
    */
    --color-gray-500: 107, 114, 128;

    /*
    * gray-600
    */
    --color-gray-600: 75, 85, 99;

    /*
    * gray-700
    */
    --color-gray-700: 55, 65, 81;

    /*
    * gray-800
    */
    --color-gray-800: 31, 41, 55;

    /*
    * gray-900
    */
    --color-gray-900: 31, 41, 55;

    /*
    * orange-50
    */
    --color-orange-50: 255, 247, 237;

    /*
    * orange-100
    */
    --color-orange-100: 255, 237, 213;

    /*
    * orange-200
    */
    --color-orange-200: 254, 215, 170;

    /*
    * orange-300
    */
    --color-orange-300: 253, 186, 116;

    /*
    * orange-400
    */
    --color-orange-400: 251, 146, 60;

    /*
    * orange-500
    */
    --color-orange-500: 249, 115, 22;

    /*
    * orange-600
    */
    --color-orange-600: 234, 88, 12;

    /*
    * orange-700
    */
    --color-orange-700: 194, 65, 12;

    /*
    * orange-800
    */
    --color-orange-800: 154, 52, 18;

    /*
    * orange-900
    */
    --color-orange-900: 124, 45, 18;
  }

  html {
    -webkit-tap-highlight-color: transparent;
  }

  body {
    @apply font-body;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-heading;
    font-weight:600;
  }

  a {
    @apply no-underline;
  }
}
* {
  scrollbar-width: thin;
  scrollbar-color: rgb(83, 116, 108) transparent;
}



@import './custom-plugins.css';

.slider-line-height{
  line-height: 70px !important;
}
@media (max-width: 768px){
  .slider-line-height{
    line-height: 40px !important;
  }
}

.swiper-pagination-bullet-active {
  color: #fff;
  background:rgb(0, 159, 127) !important;
}

.animation-pulse1 {
    /*box-shadow: 0 0 0 20px rgba(229,62,62, 0.5);
    transform: scale(0.8);*/
    animation: pulse1 2s infinite;
}

@keyframes pulse1 {
    0% {
        transform: scale(0.8);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    }
    50% {
        transform: scale(0.7);
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 60px rgba(255, 255, 255, 0);
    }

    100% {
        transform: scale(0.8);
    }
}


h2 {
  font-size: 1.60rem;
  line-height: 1.75rem;
  margin-top: 33px;
  margin-bottom: 10px;
}
h3 {
  font-size: 1.4rem;
  line-height: 1.55rem;
  margin-top: 10px;
}

h1 {
  font-size: 2rem;
  line-height: 2.1rem;
  margin-bottom: 10px;
}


.unsetul ul{
  list-style: unset;
  margin: 0 10px;
  padding: 20px;
}

#reviewsSlider .swiper-wrapper{
  display: flex;
  align-items: center;
}


.sldimage {
  position: relative;
}
.sldimageinner {
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
 background: linear-gradient(90deg, #52736bf5 0%, #52736bf2 40%, rgb(83 116 108 / 0%) 60%);
  background-blend-mode: overlay;
  
}


.searchoverlay {
  position: absolute;
  left: 0; 
  top: 0;
  width:100%;
  height: 100%;
   background: linear-gradient(90deg, #52736b 0%, #52736b8a 80%, rgba(0, 212, 255, 0) 90%);
  background-blend-mode: overlay;
  
}


.sldimageinner-login {
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
 background: linear-gradient(90deg, #52736bf5 0%, #52736bf2 40%, rgb(83 116 108 / 0%) 60%);
  background-blend-mode: overlay;
  
}

.swiper-button-prev {
    display: none;
}

.swiper-button-next {
    display: none;
}

.imagebox{
  box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
}

.logobox{
  /*box-shadow:
      1px 2px 2px hsl(165deg 16.6% 39.0% / 0.075),
      2px 4px 4px hsl(165deg 16.6% 39.0% / 0.075),
      4px 8px 8px hsl(165deg 16.6% 39.0% / 0.075),
      8px 16px 16px hsl(165deg 16.6% 39.0% / 0.075),
      16px 32px 32px hsl(165deg 16.6% 39.0% / 0.075);
      */
  background: #FFF;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 12px 0px #000000;
}

.blogtitle{
    font-size: 1.40rem;
    line-height: 1.75rem;
    margin-bottom: 10px;
    font-weight: 600;
}

.split-number {
    font-size: 60px;
    -webkit-text-stroke: 1px rgb(var(--color-accent));
    color: transparent;
    line-height: 1;
    margin-bottom: 20px;
    font-weight: 600;
}
.footer-brd{
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}